<template>
  <div class="background">
    <div style="display: flex; padding: 0px 0; align-items: center">
      <!-- <el-select
        v-model="form2.startYear"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.startMonth"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.startWeek"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <span style="margin-right: 10px">至</span>
      <el-select
        v-model="form2.endYear"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endMonth"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endWeek"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select> -->

      <el-select
        v-if="false"
        v-model="form2.Year"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="false"
        v-model="form2.Month"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="false"
        v-model="form2.Week"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>

      <el-select
        filterable
        clearable
        v-model="form2.storeId"
        style="margin-left: 0px"
        placeholder="请选择门店"
      >
        <el-option
          v-for="item in AllDoorData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>

      <el-select
        filterable
        clearable
        v-model="form2.teacherName"
        style="margin-left: 20px"
        placeholder="请选择教师"
      >
        <el-option
          v-for="item in AllTeacherData2"
          :key="item.NickName"
          :label="item.NickName"
          :value="item.NickName"
        >
        </el-option>
      </el-select>

      <!-- <el-input style="margin-left: 20px;width: 160px;" v-model='form2.teacherName' placeholder="请输入教师名字" /> -->
      <!-- 
      <el-select
        v-model="form2.teacherId"
        style="margin-left: 20px"
        placeholder="请选择教师"
      >
        <el-option
          v-for="item in AllTeacherData2"
          :key="item.NickName"
          :label="item.NickName"
          :value="item.NickName"
        >
        </el-option>
      </el-select> -->

      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="SearchTeacherFn"
      >
        搜索
      </div>
      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
        @click="resettingFn"
      >
        重置
      </div>
      <div
        style="
          width: 110px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 40px;
          cursor: pointer;
        "
        @click="openAddTeachFn"
      >
        新增教师
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px">
      <el-table
        :data="AllTeacherData"
        :header-cell-style="getRowClass"
        style="width: 100%; background-color: #fff"
        :row-style="{ height: '60px' }"
      >
        <el-table-column label="序号" prop="CardId">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column label="教师姓名" prop="NickName" />
        <el-table-column label="手机号" prop="Phone" />
        <el-table-column label="年龄" prop="Age" />
        <el-table-column label="门店" prop="StroeName" />
        <!-- <el-table-column label="任教课程" prop="Between" /> -->
        <el-table-column label="账号" prop="UserName" />
        <el-table-column label="是否店长" prop="IsManager">
          <template slot-scope="scope">
            <div v-if="scope.row.IsManager">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="上课时间" prop="IsUsed" /> -->
        <el-table-column label="创建时间" prop="AddTime" />
        <el-table-column label="操作">
          <template #default="scope">
            <!-- <el-popconfirm
                style="margin-left: 10px"
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确认删除吗？"
                @confirm="deleteKeys"
            > -->
            <span
              @click="openEditTeachFn(scope.row)"
              style="font-size: 14px; color: #007aff; cursor: pointer"
              >编辑</span
            >

            <span
              @click="openEditPwdFn(scope.row)"
              style="
                font-size: 14px;
                color: #007aff;
                cursor: pointer;
                margin-left: 10px;
              "
              >修改密码</span
            >
            <span
              style="
                font-size: 14px;
                color: #ee6e6e;
                cursor: pointer;
                margin-left: 10px;
              "
              @click="DeleteTeacherFn(scope.row)"
              >删除</span
            >

            <!-- <el-button slot="reference" type="danger" @click="handleDelete(scope)">删除</el-button> -->
            <!-- </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="AllCount"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增教师 -->
    <el-dialog title="新增教师" :visible.sync="dialogVisible1" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <el-form ref="form" :model="form">
          <div>
            <div style="display: flex; width: 100%; align-items: center">
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                教师名字
              </div>
              <el-input
                v-model="form.NickName"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                手机号
              </div>
              <el-input
                v-model="form.Phone"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                年龄
              </div>
              <el-input
                v-model="form.Age"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>

            <!-- 学生批次 -->
            <div
              v-if="false"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                学生批次
              </div>
              <el-select
                style="flex: 1"
                v-model="form.Year"
                placeholder="选择年"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.Month"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.Week"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <!-- <div style="margin: 0 5px">至</div>
              <el-select
                style="flex: 1"
                v-model="form.EndYear"
                placeholder="选择年"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.EndMonth"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.EndWeek"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select> -->

              <!-- <span
                @click="SearchTeachAnFn()"
                style="margin-left: 5px; color: #01b37c; cursor: pointer"
                >查询</span
              > -->
            </div>

            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                门店
              </div>
              <el-select
                clearable
                v-model="form.StroeId"
                style="flex: 1"
                placeholder="请选择门店"
              >
                <el-option
                  v-for="item in AllDoorData"
                  :key="item.value"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                账号
              </div>
              <el-input
                v-model="form.UserName"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                密码
              </div>
              <el-input
                v-model="form.PassWord"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                是否店长
              </div>
              <el-select
                v-model="form.IsManager"
                placeholder="请选择"
                style="flex: 1"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div
            style="
              margin-top: 40px;
              display: flex;
              width: 100%;
              justify-content: center;
            "
          >
            <div
              @click="offAddTeachFn"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #01b37c;
                background-color: #fff;
                border: 1px solid #01b37c;
                box-sizing: border-box;
                cursor: pointer;
              "
            >
              取消
            </div>
            <div
              @click="RegisterFn"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background-color: #01b37c;
                margin-left: 20px;
                cursor: pointer;
              "
            >
              确定
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- 修改教师 -->
    <el-dialog title="修改教师" :visible.sync="dialogVisible2" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <el-form ref="form" :model="form">
          <div>
            <div style="display: flex; width: 100%; align-items: center">
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                教师名字
              </div>
              <el-input
                v-model="form.NickName"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                手机号
              </div>
              <el-input
                v-model="form.Phone"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                年龄
              </div>
              <el-input
                v-model="form.Age"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>

            <!-- 学生批次 -->
            <div
              v-if="false"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                学生批次
              </div>
              <el-select
                style="flex: 1"
                v-model="form.Year"
                placeholder="选择年"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.Month"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.Week"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <!-- <div style="margin: 0 5px">至</div>
              <el-select
                style="flex: 1"
                v-model="form.EndYear"
                placeholder="选择年"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.EndMonth"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
                style="flex: 1"
                v-model="form.EndWeek"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select> -->

              <!-- <span
                @click="SearchTeachAnFn()"
                style="margin-left: 5px; color: #01b37c; cursor: pointer"
                >查询</span
              > -->
            </div>

            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                门店
              </div>
              <el-select
                v-model="form.StroeId"
                style="flex: 1"
                placeholder="请选择门店"
              >
                <el-option
                  v-for="item in AllDoorData"
                  :key="item.value"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>

            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                账号
              </div>
              <el-input
                v-model="form.UserName"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                是否店长
              </div>
              <el-select
                v-model="form.IsManager"
                placeholder="请选择"
                style="flex: 1"
                @change="isManagerChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div
            style="
              margin-top: 40px;
              display: flex;
              width: 100%;
              justify-content: center;
            "
          >
            <div
              @click="offEditTeachFn"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #01b37c;
                background-color: #fff;
                border: 1px solid #01b37c;
                box-sizing: border-box;
                cursor: pointer;
              "
            >
              取消
            </div>
            <div
              @click="UpdateTeacherFn"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background-color: #01b37c;
                margin-left: 20px;
                cursor: pointer;
              "
            >
              确定
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- 修改密码 管理员修改自己 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible3" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 60px">
              新密码
            </div>
            <el-input
              v-model="form3.newPwd"
              style="flex: 1"
              placeholder="请输入"
            />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="dialogVisible3 = false"
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateTeacherPassWordFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetAllUser,
  GetAllStore,
  Register,
  DeleteTeacher,
  UpdateTeacher,
  SearchTeacher,
  UpdateTeacherPassWord,
} from "@/request/api";

export default {
  data() {
    return {
      Nowurl: "",
      NowChoose: "",
      selectLetterValue: "",
      uurl: "http://服务器名/ocr?card=秘钥&type=类型",
      uurl1: "http://服务器ip:81/OCR/check?card=文字识别首页token",
      uurl2: "http://服务器ip:81/OCR?Token=服务器tokens.txt单个token&type=1",
      Allurl: [],
      parameterData: [
        {
          choose: "必选",
          parameterName: "card",
          type: "String",
          about: "当前用户首页的Token",
        },
        {
          choose: "非必选",
          parameterName: "type",
          type: "Int",
          about: "类型为1的时候返回文本，不填或者类型为0时返回结果带坐标",
        },
      ],
      parameterData2: [
        {
          choose: "必选",
          parameterName: "Base64",
          type: "String",
          about: "Base64格式的图片",
        },
      ],
      parameterData3: [
        {
          field: "ttxt",
          type: "String",
          about: "Base64图片识别到的文字",
        },
        {
          field: "rect",
          type: "String",
          about: "具体的坐标信息",
        },
        {
          field: "score",
          type: "String",
          about: "精确度",
        },
      ],

      //教案
      value: "",
      AllTeacherData: [], //教师列表
      AllTeacherData2: [], //教师列表
      pageIndex: 1,
      pageSize: 10,
      AllCount: 0, //总条数
      AllDoorData: [], //所有门店
      form: {
        UserName: "",
        PassWord: "",
        Age: "",
        Phone: "",
        StroeId: "",
        NickName: "",
        Year: "",
        Month: "",
        Week: "",
        IsManager: false,
        sex: 0,
        AddTime: "",
        LoginTime: "",
      },
      form3: {
        id: "",
        newPwd: "",
      },
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      options: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      weekIndex: 0,
      weekData: [
        {
          name: "时间",
          Id: 0,
          date: "",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00",
            },
            {
              name: "3岁第一周学前英语",
              time: "10:00",
            },
            {
              name: "3岁第一周学前英语",
              time: "11:00",
            },
            {
              name: "暂无课程",
              time: "14:00",
            },
            {
              name: "3岁第一周学前英语",
              time: "15:00",
            },
            {
              name: "3岁第一周学前英语",
              time: "16:00",
            },
            {
              name: "暂无课程",
              time: "19:00",
            },
            {
              name: "3岁第一周学前英语",
              time: "20:00",
            },
          ],
        },
        {
          name: "星期一",
          Id: 1,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
        {
          name: "星期二",
          Id: 2,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
        {
          name: "星期三",
          Id: 3,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
        {
          name: "星期四",
          Id: 4,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
        {
          name: "星期五",
          Id: 5,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
        {
          name: "星期六",
          Id: 6,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
        {
          name: "星期日",
          Id: 7,
          date: "2023-03-01",
          courseData: [
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
            {
              name: "暂无课程",
              time: "9:00-9:45",
            },
            {
              name: "3岁第一周学前英语",
              time: "9:00-9:45",
            },
          ],
        },
      ],
      form2: {
        // startYear: "",
        // startMonth: "",
        // startWeek: "",
        // endYear: "",
        // endMonth: "",
        // endWeek: "",
        Year: "",
        Month: "",
        Week: "",
        storeId: "",
        teacherName: "",
      },
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      YearData: [],
      MonthData: [],
      searchCheck: false,
    };
  },

  watch: {
    "form2.storeId"() {
      this.SearchTeacherFn();
    },
    "form2.teacherName"() {
      this.SearchTeacherFn();
    },
  },
  created() {
    // GetAllServiceIP().then((res) => {
    //   this.Allurl = res.Data;
    // });
    // this.GetAllUserFn(); //获取教师列表
    this.GetAllStoreFn(); //获取所有门店

    let arr = [];
    let arr2 = [];
    for (let i = 0; i < 7; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    this.YearData = arr;
    this.MonthData = arr2;
  },
  methods: {
    //教案
    // 表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      // return "background:#50a0ff;color:#fff;font-size:16px;color:#fff";
      return "background:#F8FCFE;color:#fff;font-size:16px;color:#333";
    },

    openEditPwdFn(row) {
      this.form3.id = row.Id;
      this.dialogVisible3 = true;
    },

    //修改密码
    UpdateTeacherPassWordFn() {
      let that = this;
      if (this.form3.newPwd == "") {
        this.$message({
          message: "请输入新密码",
          type: "error",
        });
        return;
      }
      UpdateTeacherPassWord(this.form3).then((res) => {
        if (res.Code === 2000) {
          that.$message({
            message: "修改成功",
            type: "success",
          });
          that.form3.newPwd = "";
          that.form3.id = "";
        } else {
          that.$message({
            message: res.Message,
            type: "error",
          });
        }
        this.dialogVisible3 = false;
      });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      if (this.searchCheck == true) {
        this.SearchTeacherFn();
      } else {
        this.GetAllStoreFn(); //
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val;
      // this.GetAllStoreFn(); //
      if (this.searchCheck == true) {
        this.SearchTeacherFn();
      } else {
        this.GetAllStoreFn(); //
      }
    },
    isManagerChange(e) {
      console.log(e);
      this.form.IsManager = e;
    },

    //打开新增教师
    openAddTeachFn() {
      this.dialogVisible1 = true;
      this.form = {
        UserName: "",
        PassWord: "",
        Age: "",
        Phone: "",
        StroeId: "",
        NickName: "",
        // Year:'',
        // Month:'',
        // Week:'',
      };
    },
    offAddTeachFn() {
      this.dialogVisible1 = false;
    },

    //打开修改教师
    openEditTeachFn(row) {
      this.form = row;
      this.dialogVisible2 = true;
    },
    offEditTeachFn() {
      this.dialogVisible2 = false;
    },

    // 管理员新增教师
    RegisterFn() {
      let that = this;
      console.log("that.form", that.form);
      if (that.form.NickName == "") {
        that.$message({
          message: "请输入教师名字",
          type: "warning",
        });
        return;
      }

      if (that.form.Phone == "") {
        that.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      if (that.form.Age == "") {
        that.$message({
          message: "请输入年龄",
          type: "warning",
        });
        return;
      }
      // if (that.form.Year == "" && that.form.Year!=0) {
      //   that.$message({
      //     message: "请选择开始年龄",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form.Month == "") {
      //   that.$message({
      //     message: "请选择开始月份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form.Week == "") {
      //   that.$message({
      //     message: "请选择开始周数",
      //     type: "warning",
      //   });
      //   return;
      // }

      if (that.form.StroeId == "") {
        that.$message({
          message: "请选择门店",
          type: "warning",
        });
        return;
      }

      if (that.form.NickName == "") {
        that.$message({
          message: "请输入账号",
          type: "warning",
        });
        return;
      }
      if (that.form.PassWord == "") {
        that.$message({
          message: "请输入教师密码",
          type: "warning",
        });
        return;
      }
      // if (that.form.IsManager == "") {
      //   that.$message({
      //     message: "请选择是否是店长",
      //     type: "warning",
      //   });
      //   return;
      // }

      console.log("管理员新增教师==>", that.form);
      Register({
        UserName: that.form.UserName,
        Password: that.form.PassWord,
        Phone: that.form.Phone,
        NickName: that.form.NickName,
        Age: that.form.Age,
        StroeId: that.form.StroeId,
        IsManager: that.form.IsManager,
        // Year: that.form.Year,
        // Month: that.form.Month,
        // Week: that.form.Week,
        // Year: '-1',
        // Month: '-1',
        // Week: '-1',
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("管理员新增教师==>", res.Data);
          that.$message({
            message: "新增成功",
            type: "success",
          });
          that.dialogVisible1 = false;
          that.GetAllUserFn(); //获取教师列表
          that.form.UserName = "";
          that.form.PassWord = "";
          that.form.Phone = "";
          that.form.NickName = "";
          that.form.Age = "";
          that.form.StroeId = "";
          that.form.Year = "";
          that.form.Month = "";
          that.form.Week = "";
        } else {
          that.$message.error(res.Message);
        }
      });
    },

    // 管理员修改教师
    UpdateTeacherFn() {
      let that = this;
      if (that.form.UserName == "") {
        that.$message({
          message: "请输入教师名字",
          type: "warning",
        });
        return;
      }
      if (that.form.PassWord == "") {
        that.$message({
          message: "请输入教师密码",
          type: "warning",
        });
        return;
      }
      if (that.form.Phone == "") {
        that.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      if (that.form.NickName == "") {
        that.$message({
          message: "请输入账号",
          type: "warning",
        });
        return;
      }
      if (that.form.Age == "") {
        that.$message({
          message: "请输入年龄",
          type: "warning",
        });
        return;
      }
      if (that.form.StroeId == "") {
        that.$message({
          message: "请选择门店",
          type: "warning",
        });
        return;
      }
      // if (that.form.IsManager == "") {
      //   that.$message({
      //     message: "请选择是否是店长",
      //     type: "warning",
      //   });
      //   return;
      // }
      console.log("管理员修改教师==>", that.form);
      UpdateTeacher(that.form)
        .then((res) => {
          console.log(res);
          if (res.Code == 2000) {
            console.log("管理员修改教师==>", res.Data);
            that.dialogVisible2 = false;
            that.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            that.$message.error(res.Message);
          }
        })
        .error((err) => {
          console.log(err);
        });

      that.GetAllUserFn(); //获取教师列表
      that.form.UserName = "";
      that.form.PassWord = "";
      that.form.Phone = "";
      that.form.NickName = "";
      that.form.Age = "";
      that.form.StroeId = "";
      that.form.Year = "";
      that.form.Month = "";
      that.form.Week = "";
      that.form.IsManager = "";
    },

    // 管理员删除教师
    DeleteTeacherFn(row) {
      let that = this;
      that
        .$confirm("确定删除该教师信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeleteTeacher({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除教师==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.GetAllStoreFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //教师列表搜索
    SearchTeacherFn() {
      let that = this;
      console.log("教师列表搜索==>", that.form2);
      // if (that.form2.Year === "") {
      //   that.$message({
      //     message: "请选择开始年龄",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form2.Month == "") {
      //   that.$message({
      //     message: "请选择开始月份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form2.Week == "") {
      //   that.$message({
      //     message: "请选择开始周数",
      //     type: "warning",
      //   });
      //   return;
      // }

      // if (that.form2.storeId == "") {
      //   that.$message({
      //     message: "请选择门店",
      //     type: "warning",
      //   });
      //   return;
      // }

      SearchTeacher({
        // Year: that.form2.Year===''?'-1':that.form2.Year,
        // Month: that.form2.Month===''?'-1':that.form2.Month,
        // Week: that.form2.Week===''?'-1':that.form2.Week,
        storeId: that.form2.storeId == "" ? "-1" : that.form2.storeId,
        teacherName: that.form2.teacherName,
        pageIndex: that.pageIndex,
        pageSize: that.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("教师列表搜索==>", res.Data.Data);
          that.searchCheck == true;

          for (let i = 0; i < res.Data.Data.length; i++) {
            const element = res.Data.Data[i];
            console.log("element==>", element);
            for (let j = 0; j < that.AllDoorData.length; j++) {
              const element2 = that.AllDoorData[j];
              console.log("element2==>", element2);
              element.Between =
                element.Year +
                "岁" +
                element.Month +
                "月" +
                element.Week +
                "周";
              //  +
              // "-" +
              // element.EndYear +
              // "年" +
              // element.EndMonth +
              // "月" +
              // element.EndWeek +
              // "周";
              if (element.StroeId == element2.Id) {
                // console.log("等");
                element.StroeName = element2.Name;
              }
            }
          }

          that.AllTeacherData = res.Data.Data;
          that.AllCount = res.Data.AllCount;
        } else {
          that.$message.error(res.Message);
        }
      });
    },

    //搜索重置
    resettingFn() {
      (this.form2 = {
        Year: "",
        Month: "",
        Week: "",
        storeId: "",
        teacherName: "",
      }),
        this.searchCheck == false;
      this.pageIndex = 1;
      this.pageSize = 10;
      this.GetAllStoreFn();
    },

    // 获取教师列表
    GetAllUserFn() {
      let that = this;
      GetAllUser({
        pageIndex: that.pageIndex,
        pageSize: that.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取教师列表==>", res.Data.Data);
          that.AllTeacherData2 = res.Data.Data;
          that.searchCheck == false;
          for (let i = 0; i < res.Data.Data.length; i++) {
            const element = res.Data.Data[i];
            console.log("element==>", element);
            for (let j = 0; j < that.AllDoorData.length; j++) {
              const element2 = that.AllDoorData[j];
              console.log("element2==>", element2);
              element.Between =
                element.Year +
                "岁" +
                element.Month +
                "月" +
                element.Week +
                "周";
              // element.StartYear +
              // "岁" +
              // element.StartMonth +
              // "月" +
              // element.StartWeek +
              // "周" +
              // "-" +
              // element.EndYear +
              // "年" +
              // element.EndMonth +
              // "月" +
              // element.EndWeek +
              // "周";
              if (element.StroeId == element2.Id) {
                // console.log("等");
                element.StroeName = element2.Name;
              }
            }
          }
          that.AllTeacherData = res.Data.Data;
          that.AllTeacherData2 = res.Data.Data;
          if (that.pageIndex == 1) {
            that.AllCount = res.Data.AllCount;
          }
          console.log("AllTeacherData==>", that.AllTeacherData);
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    // 获取所有门店
    GetAllStoreFn() {
      let that = this;
      GetAllStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有门店==>", res.Data);
          that.AllDoorData = res.Data;
          that.GetAllUserFn(); //获取教师列表
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    //复制
    copyUURL(e) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", e);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    Choose() {
      this.Nowurl = this.selectLetterValue;
      this.uurl = "http://" + this.Nowurl + "/ocr?card=xxx&type=xxx";
      // this.uurl1 = 'http://' + this.Nowurl + 'OCR/check?card=文字识别首页token';
      // this.uurl2 = 'http://' + this.Nowurl + 'OCR?Token=服务器tokens.txt单个token&type=1';
    },
  },

  computed: {
    // ...mapState(['form'])
  },
};
</script>

<style lang="less" scoped>
.out-div {
}

.indexBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.small-box {
  border-radius: 15px;
  border: 1px solid #e2e2e2;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>
